<style scoped lang="scss">
.tabBar {
  padding-top: 24px;
  width: 1200px;
  .logo {
    cursor: pointer;
    width: 54px;
    height: 54px;
    margin-right: 16px;
  }

  .title {
    cursor: pointer;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
  }
  .tabtitle {
    margin-top: 0.8rem;
    height: 60px;
    background: rgba($color: #fff, $alpha: 0.7);
    border-radius: 10px;

    padding: 0 28px;
    .value {
      font-weight: 400;
      font-size: 20px;
      color: #333333;
      text-decoration: none;
      cursor: pointer;
    }

    .active {
      font-weight: 700;
      margin-top: 10px;
      .xian {
        margin-top: 10px;
        width: 26px;
        height: 2px;
        background: #1882ef;
      }
    }
    .childern {
      z-index: 9999999999999999;
      top: 50px;
      left: -20px;
      border-radius: 10px;
      width: 180px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      .jiao {
        width: 35px;
        left: 19%;
        top: -20px;
        position: absolute;
        height: 35px;
      }
      .childrenCont {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 17px;
        height: 54px;
        color: #333333;
        border-bottom: 1px solid #e8e8e8;
      }
    }
    .childrenCont:hover {
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: 19px;
      color: #1882ef;
    }
    .childrenCont:last-child {
      border-bottom: none !important;
    }
  }
}
@media screen and (max-width: 900px) {
  .tabBar {
    width: 100%;
    padding: 1rem 1rem !important;
    .logo {
      width: 2rem;
      height: 2rem;
      margin-right: 30px;
    }
    .title {
      font-size: 1rem;
    }
    .san {
      width: 1.2rem;
      height: 1.2rem;
      right: 0px;
    }
    .labe {
      height: 100%;
    }
    :deep(.el-drawer) {
      width: 39% !important;
    }
    :deep(.el-drawer .el-drawer__body) {
      padding: 10px;
    }
    .el-drawer {
      width: 300px !important;
      .value {
        margin-bottom: 1rem;
      }
      .active {
        font-weight: 700;
        .xian {
          margin-top: 5px;
          width: 26px;
          height: 2px;
          background: #1882ef;
        }
      }
      .cch {
        height: 2rem;
        font-size: 0.8rem;
        font-weight: normal;
      }
      .childrenlabel {
        height: 100px;
      }
    }
  }
}
</style>
<template>
  <div class="tabBar box">
    <div class="f alic pos-re">
      <el-image class="logo" :src="logo" @click="goHome" />
      <div class="title">职业能力体系服务平台</div>
      <el-image class="Mo san pos-ab" :src="suolue" @click="drawer = true"></el-image>
    </div>

    <!-- PC -->
    <div class="tabtitle PC f alic box jsb">
      <div v-for="(v, i) in labelData" :key="i">
        <div
          :class="{ active: pages == v.route }"
          @click.stop="change(v, i, true)"
          class="value f f-d alic pos-re"
        >
          {{ v.value }}
          <div :class="{ active: pages == v.route }" class="xian"></div>
          <div class="pos-ab childern" v-if="v.flage">
            <img class="jiao" :src="sanjiao" />
            <div
              class="childrenCont f alic jc"
              v-for="(e, index) in v.children"
              :key="index"
              @click.stop="change(e, i, false)"
            >
              {{ e.value }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mbile -->
    <el-drawer v-model="drawer" :with-header="false" direction="rtl">
      <div class="f f-d alic labe">
        <div v-for="(v, i) in labelData" :key="i">
          <div
            :class="{ active: pages == v.route }"
            @click.stop="change(v, i, true)"
            class="value f f-d alic pos-re"
          >
            {{ v.value }}
            <div :class="{ active: pages == v.route }" class="xian"></div>

            <div :class="{ childrenlabel: flage }" v-if="v.flage">
              <div
                class="f alic jc cch"
                v-for="(e, index) in v.children"
                :key="index"
                @click.stop="change(e, i, false)"
              >
                {{ e.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { locationHrefUrl } from "@/lib/index";

export default {
  name: "tabBar",
  props: {},
  watch: {
    $route(now) {
      this.pages = now.fullPath;
    },
  },
  created() {
    this.pages = this.$route.fullPath;
  },
  data() {
    return {
      drawer: false,
      sanjiao: require("@/assets/img/sanjiao.png"),
      suolue: require("@/assets/img/3.png"),
      logo: require("@/assets/home/lgoo.png"),
      pages: "/",
      labelData: [
        {
          value: "首页",
          route: "/",
          flage: false,
          children: [],
        },
        {
          value: "证书查询",
          flage: false,
          children: [
            {
              value: "职业能力体系证书查询",
              route: "/certPerson",
            },
            {
              value: "单位证书查询",
              route: "/certFlat",
            },
          ],
          route: "/certPerson",
        },
        {
          value: "要闻动态",
          route: "/cms",
          flage: false,
          children: [],
        },
        {
          value: "法律法规",
          route: "/contactUs",
          flage: false,
          children: [],
        },
        {
          value: "合规机构查询",
          route: "/zgcx",
          flage: false,
          url: "http://zgcx.nhc.gov.cn:9090/unit",
          children: [],
        },
        {
          value: "建设单位查询",
          route: "/report",
          flage: false,
          children: [],
        },
        {
          value: "专业人员查询",
          route: "/doc",
          flage: false,
          children: [
            {
              value: "医生执业信息查询",
              route: "/doctor",
              url: "http://zgcx.nhc.gov.cn:9090/doctor",
            },
            {
              value: "护士执业信息查询",
              route: "/nurse",
              url: "http://zgcx.nhc.gov.cn:9090/nurse",
            },
          ],
        },
        {
          value: "正品器械查询",
          route: "/nmpa",
          flage: false,
          children: [],
          url: "https://www.nmpa.gov.cn/datasearch/home-index.html#category=ylqx",
        },
        {
          value: "其他信息查询",
          route: "/other",
          flage: false,
          children: [
            {
              value: "违规机构查询",
              route: "/certPerson",
              url: "https://cfws.samr.gov.cn/",
            },
            {
              value: "企业失信查询",
              route: "",
              url: "https://www.gsxt.gov.cn/index.html",
            },
          ],
        },
      ],
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    change(v, i, f) {
      console.log(f);
      this.labelData.forEach((s) => {
        s.flage = false;
      });
      if (v.url) {
        this.labelData.forEach((s) => {
          s.flage = false;
        });
        return locationHrefUrl(v.url);
      }
      if (f && v.children.length > 0) {
        this.labelData[i].flage = true;
      } else {
        this.labelData.forEach((s) => {
          s.flage = false;
        });
        this.$router.push(v.route);
      }
      this.pages = v.route;
    },

    goindex() {
      this.$router.push({
        name: "homeView",
      });
    },
  },
};
</script>
