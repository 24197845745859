import axios from 'axios'
import { useCookies } from "vue3-cookies";
import { ElMessage } from 'element-plus'
const { cookies } = useCookies();
export function request(config) {
  return new Promise((resove, rej) => {


    const instance = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      timeout: 5000,
      withCredentials: true,
      crossDomain: true,
      headers: {
        Authorization: 'Bearer' + cookies.get('token')
      }
    });
    //发送请求拦截
    instance.interceptors.request.use((config) => {
      config.headers.token = cookies.get('token')
      return config
    })
    //响应数据拦截
    instance.interceptors.response.use(
      res => {
        if (res.data.code == 10000) {
          resove(res.data.data);
        } else {
          ElMessage.error(res.data.message);
          return
        }
      }, err => {
        ElMessage.error(err.messsage);
        console.log(err);
        rej(err)
      }
    )
    return instance(config)
  })
}