<template>
  <div class="banner">
    <el-carousel
      autoplay
      :indicator-position="bannerList.length > 1 ? true : 'none'"
      class="carImg"
    >
      <el-carousel-item class="itemIMg" v-for="item in bannerList" :key="item">
        <el-image style="width: 100%; height: 100%" :src="item.url"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { getBanner } from "@/api/index";

export default {
  name: "BannerSwiper",
  props: {
    swiperHieght: {
      type: String,
      default: "475px",
    },
  },
  data() {
    return {
      bannerList: {
        type: Array,
        default: [],
      },
    };
  },
  created() {
    this.getData();
  },

  methods: {
    getData() {
      let width = document.documentElement.clientWidth;
      let id = 2;
      if (width < 1000) {
        id = 1;
      }
      getBanner({
        id,
      }).then((r) => {
        console.log(r);
        this.bannerList = r.images;
      });
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 900px) {
  .carImg {
    height: 900px !important;
    width: 100% !important;
  }
  .itemIMg {
    height: 900px !important;
    width: 100% !important;
  }
}
.el-image {
  border-radius: 15px;
}
.carImg {
  height: 475px;
}
.itemIMg {
  height: 475px;
}
</style>
