<template>
  <el-config-provider>
    <div id="app">
      <router-view />
    </div>
  </el-config-provider>
</template>
<script>
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
  }
};
</script>
<style lang="scss">
@import "@/assets/customicons.scss";
* {
  font-family: SourceHanSansCN, SourceHanSansCN;
  margin: 0;
  padding: 0;
}
</style>
