import { createStore } from 'vuex'

export default createStore({
  state: {
    phone: '',
    email: '',
    year: '',
    product_number: '',
    city_number: '',
    instance_number: '',
  },
  getters: {
    getPhone(state) {
      return state.phone
    },
    getEmail(state) {
      return state.email
    },
    getData(state) {
      return state
    },
  },
  mutations: {
    setUs(state,val){
      state.email=val.email
      state.phone=val.phone
      state.year=val.year
      state.product_number=val.product_number
      state.city_number=val.city_number
      state.instance_number=val.instance_number
    },
  },
  actions: {
  },
  modules: {
  }
})
