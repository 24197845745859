import { request } from '@/request/axios'
//报名
export const application = (params) => {
    return request({
        url: '/api/applicate',
        data: params,
        method: 'post'
    })
}
export const SiteLinks = (params) => {
    return request({
        url: '/api/cms/articles/SiteLinks',
        data: params,
        method: 'post'
    })
}
export const person = (params) => {
    return request({
        url: '/api/cert/person',
        data: params,
        method: 'post'
    })
}
export const flat = (params) => {
    return request({
        url: '/api/cert/flat',
        data: params,
        method: 'post'
    })
}
export const getBanner = (params) => {
    return request({
        url: '/api/images/carousel',
        data:params,
        method: 'POST'
    })
}
//获取文章
export const getCmsList = (params) => {
    return request({
        url: '/api/cms/articles/getList',
        data: params,
        method: 'post'
    })
}
//获取栏目
export const getTreeList = (params) => {
    return request({
        url: '/api/cms/category/getTreeList',
        data: params,
        method: 'post'
    })
}
//获取详情
export const getDetail = (params) => {
    return request({
        url: '/api/cms/articles/getDetail',
        data: params,
        method: 'post'
    })
}
//获取公共配置
export const getConfig = (params) => {
    return request({
        url: '/api/common/config',
        data: params,
        method: 'post'
    })
}


