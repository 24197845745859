import {
	createRouter,
	createWebHashHistory
} from 'vue-router'

const WebHome = () => import( /* webpackChunkName: "about" */ '../views/WebHome.vue')
const HomeView = () => import( /* webpackChunkName: "about" */ '../views/HomeView.vue')
const NewsHome = () => import( /* webpackChunkName: "about" */ '../views/NewsHome.vue')
const NewsDetail = () => import( /* webpackChunkName: "about" */ '../views/NewsDetail.vue')
const Report = () => import( /* webpackChunkName: "about" */ '../views/Report.vue')
const ContactHome = () => import( /* webpackChunkName: "about" */ '../views/ContactHome.vue')
const AboutHome = () => import( /* webpackChunkName: "about" */ '../views/AboutHome.vue')
const AboutComHome = () => import( /* webpackChunkName: "about" */ '../views/AboutComHome.vue')
const routes = [{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			requireAuth: false //这个参数 true 代表需要登录才能进入
		},
		children: [
			{
				path: '/',
				name: 'homeView',
				component: WebHome,
				meta: {
					requireAuth: false
				}
			},
			{
				path: '/cms',
				name: 'newshome',
				component: NewsHome,
				meta: {
					requireAuth: false
				}
			},
			{
				path: '/cmsDetail',
				name: 'newsDetail',
				component: NewsDetail,
				meta: {
					requireAuth: false
				}
			},
			{
				path: '/report',
				name: 'report',
				component: Report,
				meta: {
					requireAuth: false
				}
			},
			{
				path: '/contactUs',
				name: 'contactHome',
				component: ContactHome,
				meta: {
					requireAuth: false
				}
			},
			{
				path: '/certPerson',
				name: 'aboutHome',
				component: AboutHome,
				meta: {
					requireAuth: false
				}
			},
			{
				path: '/certFlat',
				name: 'aboutComHome',
				component: AboutComHome,
				meta: {
					requireAuth: false
				}
			},
		]
	},

]
const router = createRouter({
	history: createWebHashHistory(),
	routes
})
router.beforeEach((to, _form, next) => {
	let requireAuth = to.meta.requireAuth
	// let token = cookies.get('token')
	document.documentElement.scrollTop = 0;
	if (requireAuth) {
		//   if (token) {
		//     next()
		//   } else {
		next({
			'path': '/home'
		})
		//   }
		// } else {

	} else {
		next()
	}
})
export default router