<style scoped lang="scss">
.Side {
  margin-top: 40px;
  width: 100%;
  bottom: 0;
  background: #ebebf1;
}
.PC {
  height: 60px;
  width: 1200px;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}
.icp {
  margin-left: 21px;
}
.Mo {
  height: 2rem;
  padding: 0 30px;
  font-weight: 400;
  font-size: 0.6rem;
  color: #999999;
}
</style>
<template>
  <div class="Side f alic f-d jc">
    <!-- <div>

    </div> -->
    <div class="PC f alic">
      <div>版权所属：中国整形美容协会</div>
      <div class="icp">京ICP备18002715号-8X</div>
      <div class="icp">联系电话：{{ tel }}</div>
    </div>
    <div class="Mo f alic">
      <div>版权所属：中国整形美容协会</div>
      <div class="icp">京ICP备18002715号-8X</div>
      <div class="icp">联系电话：{{ tel }}</div>
    </div>
  </div>
</template>
<script>
import { getConfig } from "@/api/index";

export default {
  name: "BottomBar",

  data() {
    return {
      tel: "",
    };
  },
  created() {
    this.goPhone();
  },
  methods: {
    goPhone() {
      getConfig().then((r) => {
        this.tel = r.tel;
      });
    },
  },
};
</script>
