import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import locale from 'element-plus/es/locale/lang/zh-cn'

import store from './store'
import VueCookies from 'vue3-cookies'
import ElementPlus from 'element-plus'
import TabBar from '@/components/TabBar'
import BottomBar from '@/components/BottomBar'
import BannerSwiper from '@/components/BannerSwiper'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.component('TabBar', TabBar)
app.component('BottomBar', BottomBar)
app.component('BannerSwiper', BannerSwiper)
app.use(ElementPlus, {locale})
app.config.globalProperties.$cookies = VueCookies
app.use(store).use(router).mount('#app')

